.bgGray {
    background: var(--bg-gray);
}

.bgBase {
    background: var(--color-base);
    color: #fff;
}

.bgpt {
    padding-top: 50px;

    @media (min-width: 768px) {
        padding-top: 105px;
    }
}

.bgpb {
    padding-bottom: 50px;

    @media (min-width: 768px) {
        padding-bottom: 105px;
    }
}

.smt {
    margin-top: 50px;

    @media (min-width: 768px) {
        margin-top: 105px;
    }
}

.smb {
    margin-bottom: 50px;

    @media (min-width: 768px) {
        margin-bottom: 105px;
    }
}

.spt {
    padding-top: 50px;

    @media (min-width: 768px) {
        padding-top: 105px;
    }
}

.spb {
    padding-bottom: 50px;

    @media (min-width: 768px) {
        padding-bottom: 105px;
    }
}