.modal {
  position: relative;
  width: 100;
  max-width: 700px;
  background: #fff;
  border-radius: 6px;
  padding: 30px;

  &__overlow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}