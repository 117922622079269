.container {
    width: 100%;
    display: block;

    @media (min-width: 768px) {
        width: auto;
        max-width: 310px;
    }

    @media (min-width: 1000px) {
        width: 100%;
        max-width: 310px;
    }

    @media (min-width: 1240px) {
        max-width: 386px;
    }
}

.search {
    width: 100%;
    background: var(--color-fields);
    padding: 3px 3px 3px 10px;
    border-radius: 6px;
    display: flex;

    @media (min-width: 768px) {
        background: transparent;
    }

    @media (min-width: 1000px) {
        background: var(--color-fields);
    }

    &__input {
        width: 100%;
        font-family: var(--font-base);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-text);
        background-color: transparent;
        outline: none;
        border: 0;
        margin-right: 10px;

        @media (min-width: 768px) {
            display: none;
        }

        @media (min-width: 1000px) {
            display: block;
        }
    }

    &__btn {
        width: 53px;
        height: 44px;
        background: var(--color-base);
        border-radius: 4px;
        border: 0;
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 480px) {
            width: 44px;
        }

        @media (min-width: 768px) {
            width: 29px;
            height: 29px;
        }
    }
}

.spotlight {
    position: fixed;
    inset: 0px;
    z-index: 9999;
    display: none;

    &_open {
        display: block;
    }

    &_search {
        position: relative;

        i {
            pointer-events: none;
            position: absolute;
            z-index: 1;
            left: 10px;
            top: 14px;
            width: 20px;
            height: 20px;
            color: var(--color-gray);
        }
    }

    &_input {
        height: 50px;
        line-height: 48px;
        appearance: none;
        resize: none;
        box-sizing: border-box;
        font-size: 18px;
        width: 100%;
        color: var(--color-text);
        display: block;
        text-align: left;
        min-height: 50px;
        padding-right: 16.6667px;
        border-radius: 4px 4px 0px 0px;
        outline: none;
        border: 0px;
        background-color: transparent;
        padding-left: 50px;

        &::placeholder {
            color: var(--color-gray);
        }
    }

    &_list {
        display: flex;
        flex-direction: column;

        @media (max-width: 479px) {
            max-height: 60vh;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 16px;
            padding: 10px 12px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background: var(--color-fields);
            }
        }

        &__img {
            color: rgb(134, 142, 150);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        &__content {
            flex-grow: 0;
        }

        &__name {
            color: inherit;
            font-size: inherit;
            line-height: 1.55;
            text-decoration: none;
        }

        &__desc {
            color: rgb(134, 142, 150);
            font-size: 12px;
            line-height: 1.55;
            text-decoration: none;
        }
    }
}

.close {
    opacity: 0;
}

.wrapper {
    outline: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    text-decoration: none;
    color: rgb(0, 0, 0);
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px;
    position: relative;
    z-index: 2;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 100%;
    max-width: 600px;
    overflow: auto;
    margin-left: calc(var(17px, 0px) * -1);
}

.opacity {
    transition-property: opacity;
    transition-duration: 75ms;
    transition-timing-function: ease;
    opacity: 1;
}

.inner {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0.6s;

    @media (max-width: 479px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (min-width: 480px) {
        padding-top: 120px;
    }

}

.overlay {
    z-index: 1;
    position: fixed;
    inset: 0px;
    backdrop-filter: blur(3px);
}

.overDark {
    z-index: 1;
    position: fixed;
    inset: 0px;
    background-color: rgb(0, 0, 0);
    opacity: 0.25;
    border-radius: 0px;
}