:root {
  --quiz-i: #BF0000;
  --quiz-expert: #5A6C7D;
}

.quiz {
  display: grid;
  row-gap: 50px;
  margin-top: 50px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    column-gap: 20px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(12, 1fr);
  }


  &_isForm .contact {
    grid-column: 1;

    @media (min-width: 1000px) {
      grid-column: 6/10;
    }
  }
}

.content {
  grid-column: 1;

  @media (min-width: 1000px) {
    grid-column: 1/9;
  }
}

.questions {
  display: block;

  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    margin-bottom: 27px;
  }

  &__error {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ff6965;
  }

  &_variants {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

  }
}

.contact {
  grid-column: 1;
  padding: 30px 40px 30px 30px;

  @media (max-width: 479px) {
    padding: 20px 15px 20px 15px;
  }

  @media (min-width: 1000px) {
    padding: 30px 70px 30px 40px;
    grid-column: 9/13;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 5px;

  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-base);
  }

  &_list {
    margin-top: 30px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (min-width: 1000px) {
      margin-top: 44px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--color-base);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      width: 100%;


      @media (min-width: 480px) {
        width: 50%;
      }

      @media (min-width: 1000px) {
        width: 100%;
      }

      i {
        font-size: 34px;
        color: var(--quiz-i);
        margin-right: 20px;
      }

      span {
        color: var(--quiz-i);
      }

      &+& {
        @media (max-width: 479px) {
          margin-top: 30px;
        }

        @media (min-width: 1000px) {
          margin-top: 38px;
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 30px;

      @media (min-width: 1000px) {
        margin-top: 44px;
      }

      &>div {
        margin-left: 20px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: var(--quiz-expert);
      }
    }
  }
}

.navigate {
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }

  &__size {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
      margin-left: 50px;
    }
  }
}

.btn {
  display: inline-block;
  padding: 20px 64px;
  color: #fff;
  background: #BF0000;
  text-transform: uppercase;
  border: 1px solid #BF0000;
  transition: all 0.5s;
  min-width: 288px;

  @media (max-width: 479px) {
    padding: 20px 24px;
    min-width: 100%;
  }

  &:hover {
    background: transparent;
    border-color: #fff;
  }

  &:disabled {
    background: rgba(191, 0, 0, 0.3);
    border-color: rgba(191, 0, 0, 0.3);
  }
}

.input {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--input-color);
  font-family: var(--font-base);
  background: #fff;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  display: block;
  outline: none;
  padding: 20px 16px;

  &__error {
    margin-top: 10px;
    font-size: 0.9em;
    color: #ff6965;
  }
}

.result {
  grid-column: 1/5;

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

  &_form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}