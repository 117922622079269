.list {
  display: block;

  &_item {
    font-size: 15px;
    color: var(--color-text);
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;

    ul {
      margin-top: 16px;
      margin-left: 20px;

      li::marker {
        content: '- ';
        font-size: 1.2em;
      }
    }
  }

  &_link {
    display: inline-block;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 40px;
    color: var(--color-focused1);
  }
}