.container {
    position: relative;
    padding: 7px 0;
    overflow: hidden;
}

.menu {
    display: flex;
    grid-column: main-content-start/main-content-end;
    width: 100%;
    flex-direction: column;
    padding-top: 10px;

    &__link {
        display: block;
        margin-bottom: 15px;

        a {
            color: #FFFFFF;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.2;
        }
    }
}