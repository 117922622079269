.form {
  text-align: center;
  width: 100%;
  max-width: 392px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(140, 141, 141, 0.1), 0px -4px 10px rgba(140, 141, 141, 0.1), 4px 0px 10px rgba(140, 141, 141, 0.1), -4px 0px 10px rgba(140, 141, 141, 0.1);
  padding: 30px;

  @media (max-width: 479px) {
    padding: 20px;
  }


  &_template {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  i {
    font-size: 24px;
    color: var(--bg-short);
    margin-right: 16px;

    @media (max-width: 479px) {
      font-size: 24px;
      margin-right: 7px;
    }
  }

  &__txt {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: var(--color-text);

    @media (max-width: 479px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.desc {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: var(--color-text);
  margin-bottom: 25px;
  text-align: left;

  @media (max-width: 479px) {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.personalData {
  margin-top: 25px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: var(--color-signatures);

  a {
    font: inherit;
    color: var(--color-focused1);
  }
}

.btn {
  cursor: pointer;
  width: 100%;
  background: var(--btn-red);
  border: 1px solid var(--btn-red);
  color: #fff;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);
  border-radius: 5px;
  text-transform: uppercase;
  padding: 21px 30px;
  transition: all 0.6s;

  @media (max-width: 479px) {
    padding: 20px 10px;
  }

  &:hover {
    background: var(--btn-red_hover);
    border: 1px solid var(--btn-red_hover);
  }

  &:active {
    background: var(--btn-red_active);
    border: 1px solid var(--btn-red_active);
  }

  &:disabled {
    background: var(--color-signatures);
    border: 1px solid var(--color-signatures);
  }
}