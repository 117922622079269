.card {
  position: relative;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  padding-top: 142.56%;

  &Content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: drop-shadow(-4px 0px 10px rgba(140, 141, 141, 0.1));
    }
  }
}

.label {
  position: absolute;
  top: 13px;
  right: 0;
  padding: 1px 6.5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282D3C;
  background: #FFDD34;
  border-radius: 6px;
}

.content {
  position: relative;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  div {
    margin-bottom: 16px;
  }

  a {
    margin-top: auto;
  }
}

.duration {
  margin-top: -12px;
  margin-bottom: 8px;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #FFFFFF;
  background: #131F39;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #282D3C;
}

.price {
  padding-left: 7px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #282D3C;
  border-left: 3px solid #BF0000;
}

.description {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #5A6C7D;
}

.link {
  cursor: pointer;
  color: #1935C8;

  @media (max-width: 479px) {
    width: 100%;
    text-align: center;
  }

}