.section {
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--color-text);

  @media (min-width: 992px) {
    margin-top: 70px;
    margin-bottom: 70px;
  }

}

.pmb30 {
  padding-bottom: 30px;
  color: var(--color-text);
}

.h3 {
  font-size: 20px;
  padding-bottom: 30px;
  color: var(--color-text);
}