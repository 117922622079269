.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &_name {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #282D3C;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #282D3C;

  }

  &__value {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #282D3C;
  }
}

.contact {
  display: grid;
  gap: 20px;
  margin-top: 30px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 902px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1040px) {
    grid-template-columns: repeat(5, 1fr);
  }

  &_block {
    display: flex;
    flex-direction: column;
  }

  &_title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #282D3C;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      margin-left: 8px;
    }
  }

  &__text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #282D3C;
  }

  &__link {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1935C8;
  }

  &__phone {
    margin-top: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #1935C8;
  }

  &__desc {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #282D3C;
  }
}

.iphone {
  font-size: 18px;
  color: #7BDB79;
}

.iemail {
  font-size: 18px;
}

.ibg {
  display: grid;
  align-items: center;
  justify-self: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  margin: 0;
}

.viber {
  background: #7360F2;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.whatsapp {
  background: #25D366;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.telegram {
  background: #28A8E8;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}