.modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 440px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #282D3C;
  margin-bottom: 30px;
}

.info {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #282D3C;

  b {
    font-weight: normal;
    color: #1935C8;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--font-base);
  color: var(--input-color);
  padding: 15px 16px 30px;
  width: 100%;
  height: 73px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.btn {
  margin-top: 25px;
  padding: 20px 25px;
  background: var(--btn-red);
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
  display: inline-block;
  transition: all 0.6s;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);
  border-radius: 5px;

  &:active,
  &:hover {
    background: var(--btn-darken);
  }

  &[disabled] {
    background: #c7c7c7;
  }
}