.modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 440px;
}

.title {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #282D3C;
  margin-bottom: 30px;

  @media (min-width: 480px) {
    font-size: 15px;
  }

}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;

  &__desc {
    width: calc(100% - 60px);
    padding-left: 15px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #5A6C7D;

    @media (min-width: 480px) {
      font-size: 15px;
    }
  }
}

.agreement {
  color: var(--input-color);
  font-family: var(--font-base);
  font-size: 14px;
  margin-top: 10px;

  b {
    font-weight: normal;
    color: #1935C8;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.textarea {
  padding: 20px 15px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #5A6C7D;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  width: 100%;
}

.input {
  padding: 20px 15px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--input-color);
  font-family: var(--font-base);
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  width: 100%;
}

.btn {
  padding: 20px 25px;
  background: var(--btn-red);
  border-radius: 0px 5px 5px 0px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
  display: inline-block;
  transition: all 0.6s;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);

  &:active,
  &:hover {
    background: var(--btn-darken);
  }

  &[disabled] {
    background: #c7c7c7;
  }
}

.communicationMethod {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_title {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #5A6C7D;
    margin-right: 10px;

    @media (max-width: 400px) {
      max-width: 60%;
    }

  }

  &_change {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    input {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }

    input:checked+label[for='whatsap'] {
      background: #25D366;
    }

    input:checked+label[for='telegram'] {
      background: #27A6E6;
    }

    input:checked+label[for='viber'] {
      background: #7360F2;
    }
  }
}

.whatsap {
  position: relative;
  width: 25px;
  height: 25px;
  background: #bbb;
  color: #fff;
  border-radius: 50%;
  font-size: 0.9em;
  cursor: pointer;

  i {
    position: absolute;
    top: 50%;
    transform: translate(2px, -50%);
  }
}

.telegram {
  position: relative;
  width: 25px;
  height: 25px;
  background: #bbb;
  color: #fff;
  border-radius: 50%;
  font-size: 0.9em;
  cursor: pointer;

  i {
    position: absolute;
    top: 53%;
    transform: translate(1px, -50%);
  }
}

.viber {
  position: relative;
  width: 25px;
  height: 25px;
  background: #bbb;
  color: #fff;
  border-radius: 50%;
  font-size: 0.9em;
  cursor: pointer;

  i {
    position: absolute;
    top: 52%;
    transform: translate(2px, -50%);
  }
}