@import url('../../styles/variable.scss');

.slider {
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, rgb(255 255 255) 50%, rgb(19 31 57) 50%);
}

.heading {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: var(--color-base);

  @media (min-width: 992px) {
    font-size: 36px;
  }

}

.container {
  display: grid;
  grid-template-columns: [left] 0rem [main-content-start] repeat(12, 1fr) [main-content-end right-space] 0rem [right];
  grid-column-gap: 20px;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

.columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: main-content-start/main-content-end;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

.swiper {
  width: 100%;
}

.navigation {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  top: -50%;
  transform: translateY(50%);


  &__prev,
  &__next {
    position: absolute;
    left: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-fields);
    color: var(--color-base);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;

    &.swiper-button-disabled {
      opacity: 0.4;
    }

    i {
      font-size: 30px;
    }
  }

  &__next {
    left: auto;
    right: 24px;
  }
}