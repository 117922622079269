.heading {
  margin-bottom: 30px;
  color: var(--color-text);
  margin-bottom: 25px;

  &--top {
    margin-top: 30px;
  }
}

.desc {
  margin-bottom: 50px;
  color: var(--color-text);
  line-height: 1.5;

  br {
    @media (max-width: 480px) {
      display: none;
    }
  }
}

.grid {
  display: grid;
  column-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.btn {
  margin: 30px auto 0;
  display: table;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);
  border-radius: 5px;
}