.row {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column: main-content-start/main-content-end;
  width: 100%;

  @media (min-width: 1001px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .heading {
    margin-top: 0;
    margin-bottom: 40px;

    @media (min-width: 1001px) {
      margin-bottom: 0;
    }
  }
}

.slider {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  .swiper {
    margin: -10px -10px -15px;
    padding: 10px 10px 15px;
    max-width: 290px;

    @media (min-width: 481px) {
      max-width: 100%;
    }

    &Slide {
      display: flex;
    }
  }
}

.navigation {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;

  @media (min-width: 481px) {
    display: none;
  }

  &Prev {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #131F39;
    font-size: 30px;
    color: #FFFFFF;
    pointer-events: auto;
  }

  &Next {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #131F39;
    font-size: 30px;
    color: #FFFFFF;
    pointer-events: auto;
  }
}

.link {
  cursor: pointer;
  color: #1935C8;
}