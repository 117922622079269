.block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imgBlock {
  position: relative;
  width: 100%;
  display: inline-block;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-text);
  margin-top: 24px;
  margin-bottom: 18px;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--color-text);
  margin-bottom: 18px;
}

.info {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-text);
  margin-bottom: 18px;
}

.link {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-focused1);
}