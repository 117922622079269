.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}

.desc {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
}

.image {
  margin-top: 40px;
  margin-bottom: 70px;
}

.btn {
  margin: 30px auto 0;
  display: table;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);
  border-radius: 5px;
}