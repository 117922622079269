@font-face {
  font-family: 'icon';
  src: url('../font/icon.eot?46124450');
  src: url('../font/icon.eot?46124450#iefix') format('embedded-opentype'),
       url('../font/icon.woff2?46124450') format('woff2'),
       url('../font/icon.woff?46124450') format('woff'),
       url('../font/icon.ttf?46124450') format('truetype'),
       url('../font/icon.svg?46124450#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon';
    src: url('../font/icon.svg?46124450#icon') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-anglebottom:before { content: '\e800'; } /* '' */
.icon-angleleft:before { content: '\e801'; } /* '' */
.icon-angleright:before { content: '\e802'; } /* '' */
.icon-angletop:before { content: '\e803'; } /* '' */
.icon-close:before { content: '\e804'; } /* '' */
.icon-feedback:before { content: '\e805'; } /* '' */
.icon-phone:before { content: '\e806'; } /* '' */
.icon-search:before { content: '\e807'; } /* '' */
.icon-whatsapp:before { content: '\e808'; } /* '' */
.icon-plus:before { content: '\e809'; } /* '' */
.icon-long_arrow_right:before { content: '\e80a'; } /* '' */
.icon-check:before { content: '\e80b'; } /* '' */
.icon-angle-left:before { content: '\e80c'; } /* '' */
.icon-angle-right:before { content: '\e80d'; } /* '' */
.icon-email:before { content: '\e80e'; } /* '' */
.icon-whatsapp-1:before { content: '\e80f'; } /* '' */
.icon-telegram:before { content: '\e810'; } /* '' */
.icon-viber:before { content: '\e811'; } /* '' */
.icon-attach:before { content: '\e812'; } /* '' */
