.services {
    margin-top: 30px;
    width: 100%;
    padding-left: calc((100vw - var(--container))/ 2);
    margin-left: auto;
    margin-right: 0;
}

.swiper {
    padding: 10px 0 10px 20px;
}

.swiperSlide {
    width: 289px;
}


.navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: var(--container);
    margin: 30px auto 20px auto;

    @media (min-width: 992px) {
        margin: 50px auto 20px auto;
    }


    &__prev,
    &__next {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-base);
        color: #fff;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.4;
        }

        i {
            font-size: 30px;
        }
    }

    &__next {
        margin-left: 17px;
    }
}