.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}

.desc {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.btn {
  margin-top: 24px;
  margin-bottom: 50px;
}

.reviews {
  &_grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 670px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 960px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &_item {
    display: inline-block;
  }
}

.revText {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 75px;
  max-width: 700px;

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-text);
    margin-bottom: 15px;
  }

  &__author {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-signatures);
  }
}

.btnDelta {
  margin: 30px auto 0;
  display: table;
  box-shadow: 3px 4px 4px rgba(191, 0, 0, 0.25);
  border-radius: 5px;
}

.btn_load {
  cursor: pointer;
  margin-top: 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1935C8;
  display: inline-block;
}