.wrapper {}

.prices {
    grid-column: main-content-start/main-content-end;
    width: 100%;
    max-width: 701px;
    margin-top: 24px;
    margin-bottom: var(--section-mb);
    display: flex;
    flex-direction: column;
}

.next {
    margin-top: 24px;
    color: var(--color-focused1);
    font-family: var(--font-base);
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
}