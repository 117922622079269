.variant {
  display: flex;
  justify-content: space-between;

  width: 100%;
  min-height: 60px;
  background: #FFFFFF;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.02), 2px 0px 2px rgba(0, 0, 0, 0.05), -2px 0px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 7px 4px 0 16px;
  cursor: pointer;

  &_noImg {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 7px 16px;

    .value {
      width: calc(100% - 42px);
    }
  }
}

.img {
  width: 73px;
  height: 53px;
}

.value {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-base);
  width: calc(100% - 73px - 25px);
  padding-left: 15px;
  padding-right: 10px;
}

.radio {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid #D9D9D9;
}

.active {
  background: #1935C8;
}