.listOfParameters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 40%));
  row-gap: 12px;
  column-gap: 20px;

  &>div {
    padding: 10px;
    background: #F5F5F5;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #0A3186;
  }
}

.link {
  display: inline-block;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
  color: var(--color-focused1);
}