.cookie {
  position: fixed;
  width: 100%;
  z-index: 99;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  max-width: 488px;
  box-sizing: border-box;
  background-color: #000000e6;
  color: #fff;
  border-radius: 4px;

  @media (min-width: 768px) {
    bottom: 20px;
  }

}

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  color: #fff;
  border-bottom: 1px dotted #fff;
}

.close {
  position: relative;
  margin-left: auto;
  right: 0;
  cursor: pointer;
}