.companyInfo {
    display: flex;
    flex-direction: column;

    &_list {
        align-items: center;
        flex-direction: row;

        @media (min-width: 320px) {
            display: none;
        }

        @media (min-width: 768px) {
            display: flex;
        }
    }

    &+& {
        @media (min-width: 768px) {
            margin-left: 48px;
        }

        @media (min-width: 1000px) {
            margin-left: 30px;
        }

        @media (min-width: 1240px) {
            margin-left: 48px;
        }
    }

    &__title {
        color: var(--color-signatures);
        font-weight: 400;
        line-height: 1.8;

        @media (min-width: 768px) {
            font-size: 12px;
        }

        @media (min-width: 1240px) {
            font-size: 13px;
        }
    }

    &__phone {
        color: var(--color-link);
        font-weight: 600;
        line-height: 1.38;
        margin-top: 3px;
        margin-bottom: 4px;
        cursor: pointer;

        @media (min-width: 768px) {
            font-size: 16px;
        }

        @media (min-width: 1240px) {
            font-size: 16px;
        }
    }

    &__link {
        color: var(--color-focused1);
        font-weight: 400;
        line-height: 1.1;
        cursor: pointer;

        @media (min-width: 768px) {
            font-size: 12px;
        }

        @media (min-width: 1000px) {
            font-size: 13px;
        }
    }
}