.skeleton {
    position: relative;
    width: 100%;
    height: 340px;
    z-index: 0;
    overflow: hidden;
    background: 0 0;

    &::after {
        position: absolute;
        inset: 0 -150%;
        background: linear-gradient(90deg, rgba(190, 190, 190, .2) 25%, rgba(129, 129, 129, .24) 37%, rgba(190, 190, 190, .2) 63%);
        animation: ant-skeleton-loading 1.4s ease infinite;
        content: "";
    }
}


@keyframes ant-skeleton-loading {
    0% {
        transform: translate(-37.5%)
    }

    to {
        transform: translate(37.5%)
    }
}