@import url("assets/css/reset.css");
@import url("assets/css/icon.css");
@import url('styles/variable.scss');

body {
  margin: 0;
  font-size: var(--font-size);
  font-family: var(--font-base), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-base);
  scrollbar-gutter: stable;
}

a {
  color: var(--color-base);
  font-family: var(--font-base);
  font-size: var(--font-size);
  font-weight: normal;
  text-decoration: none;
}

a:hover {
  color: var(--color-focused);
}

b {
  font-weight: bold;
}

img {
  max-width: 100%;
}

body::selection {
  background: var(--color-focused);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  display: inline-block;
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.container {
  @extend .container;
}

.row {
  @extend .row;
}

.columns {
  @extend .columns;
}

.flex-row-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: main-content-start/main-content-end;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

h1,
.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  font-style: normal;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;

  }

}

h2,
.h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  font-style: normal;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;

  }
}

h3,
.h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-style: normal;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;

  }
}


.heading {
  width: 100%;
  max-width: 619px;
  margin-top: var(--section-mt);

  &.m600 {
    max-width: 600px;

    br {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  &_description {
    margin-top: 24px;
    line-height: 18.15px;
    font-weight: 500;
  }
}

.section-mt {
  margin-top: var(--section-mt);
}

.section-mb {
  margin-bottom: var(--section-mb);
}

.section-pt {
  padding-top: var(--section-mt);
}

.section-pb {
  padding-bottom: var(--section-mb);
}