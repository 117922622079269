.checkbox {
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked+label {
        background: #BF0000;
        color: #fff;
    }
}

.error {
    display: block;
    text-align: left;
    color: var(--input-border_err);
    margin-top: 7px;
    font-size: 0.9em;
}

.social {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;

    img {
        width: 25px;
    }
}

.label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 8px 16px;
    background: #EBE8E8;
    border-radius: 5px;
    cursor: pointer;
    color: #282D3C;

    &__title {
        margin-left: 16px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: var(--input-color);
        font-family: var(--font-base);
    }
}