.search {
    width: 100%;
    background: var(--color-fields);
    padding: 4px 4px 4px 15px;
    border-radius: 6px;
    display: flex;

    &__input {
        width: 100%;
        font-family: var(--font-base);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-text);
        background-color: transparent;
        outline: none;
        border: 0;
        margin-right: 10px;
    }

    &__btn {
        background: var(--color-base);
        border-radius: 4px;
        border: 0;
        color: #fff;
    }
}

.btn_xs {
    width: 20px;
    height: 20px;
}

.btn_md {
    width: 29px;
    height: 29px;
}

.btn_lg {
    width: 37px;
    height: 37px;
}