.sliderList {
  margin-top: 30px;
  background: linear-gradient(180deg, #fff 63%, #131F39 63%);
}

.swiper {
  width: 100%;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 36px;

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-base);
    color: #fff;
    width: 50px;
    height: 50px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: 0.4;
    }

    i {
      font-size: 30px;
    }
  }

  &__next {
    margin-left: 17px;
  }
}

/**** Стили одного блока ****/

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 15px;
  background: #fff;
  z-index: 1;
  border-radius: 0 0 10px 10px;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #282D3C;
}

.certificate {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #282D3C;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}