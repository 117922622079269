.background {
  background: #F5F5F5;
  padding-bottom: 105px;
}

.heading {
  p {
    margin-bottom: 40px;
  }
}

.reviews {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-focused1);

  &Text {
    line-height: 18px;
    margin-bottom: 24px;

    p + p {
      margin-top: 14px;
      color: #5A6C7D;
    }

    b {
      font-weight: 500;
    }
  }
}

.container {
  display: grid;
  grid-column: main-content-start/main-content-end;
  grid-template-columns: [left] 0rem [main-content-start] 1fr [main-content-end right-space] 0rem [right];
  width: 100%;
  max-width: var(--container);
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-column-gap: 20px;
    grid-template-columns: [left] 0rem [main-content-start] repeat(3, 1fr) [main-content-end right-space] 0rem [right];
  }
  
  @media (min-width: 1000px) {
    grid-template-columns: [left] 0rem [main-content-start] repeat(12, 1fr) [main-content-end right-space] 0rem [right];
  }
}

.slider {
  position: relative;
  margin-top: var(--pt);
  grid-column: 2;
  display: flex;
  justify-content: center;

  @media (min-width: 481px) {
    display: grid;
  }

  @media (min-width: 768px) {
    grid-column: 1/3;
  }

  @media (min-width: 1000px) {
    grid-column: 1/8;
  }

  .swiper {
    padding: 10px 10px 15px;
    margin: -10px -10px -15px;
    max-width: 290px;
  
    @media (min-width: 481px) {
      max-width: none;
    }
  
    &Slide {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      img {
        width: 100%;
      }
    }
  }
}

.navigation {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;

  @media (min-width: 481px) {
    display: none;
  }

  &Prev {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #131F39;
    font-size: 30px;
    color: #FFFFFF;
    pointer-events: auto;
  }

  &Next {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #131F39;
    font-size: 30px;
    color: #FFFFFF;
    pointer-events: auto;
  }
}

.block {
  display: flex;
  flex-direction: column;
  padding-top: var(--pt);
  grid-column: 2;

  @media (min-width: 768px) {
    grid-column: 3/6;
  }

  @media (min-width: 1000px) {
    grid-column: 8/14;
  }

  a {
    margin-top: auto;
  }
}

