.heading {
  margin-bottom: 36px;

  @media (min-width: 768px) {
    margin-bottom: 50px;
  }

  h2 {
    margin-top: 21px;

    @media (min-width: 768px) {
      margin-top: 27px;
    }
  }
}

.list {
  margin: 0 0 -24px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin: 0 -10px -40px;
  }
}

.item {
  margin: 0 10px 24px;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  display: flex;

  @media (min-width: 768px) {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

.icon {
  margin-right: 33px;
  flex: 0 0 50px;
  width: 50px;
  max-width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F5F5;
  border-radius: 50%;
  color: #1935C8;
  font-size: 26px;

  @media (min-width: 768px) {
    flex: 0 0 70px;
    width: 70px;
    max-width: 70px;
    height: 70px;
    font-size: 31px;
  }

  &--red {
    color: #BF0000;
  }
}

.content {
  @media (min-width: 768px) {
    max-width: 392px;
  }
}

.title {
  margin-bottom: 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  @media (min-width: 480px) {
    line-height: 29px;
    font-size: 24px;
  }

}

.description {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}