.flex {
  display: flex;
  flex: auto;
  min-height: 0;
  flex-direction: column;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.row {
  flex-direction: row;
}

.navigation {
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
  margin-bottom: 25px;
  gap: 8px;

  a,
  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    color: #5A6C7D;
    cursor: default;
  }

  a {
    cursor: pointer;
  }
}