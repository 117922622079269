.formInput {
    display: flex;
    flex-direction: column;
}

.input {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--input-color);
    font-family: var(--font-base);
    background: #fff;
    border: 1px solid var(--input-border);
    border-radius: 5px;
    display: block;
    outline: none;

    &_error {
        border-color: var(--input-border_err);

        &::placeholder {
            color: var(--input-border_err);
        }
    }

    &_xs {
        padding: 15px 10px;
    }

    &_md {
        padding: 20px 16px;
    }

    &_lg {
        padding: 30px 20px;
    }

    &::placeholder {
        color: var(--color-signatures);
    }

    &:focus {
        border: 1px solid var(--color-primary);
    }
}

.error {
    display: block;
    text-align: left;
    color: var(--input-border_err);
    margin-top: 7px;
    font-size: 0.9em;
}