.city {
  @media (min-width: 320px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }

  &_info {
    display: flex;
    font-size: 15px;
    line-height: 1.3;
    font-weight: 400;
    color: var(--color-signatures);

    span {
      margin-left: 8px;
      color: var(--color-focused1);
      cursor: pointer;
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;

      &:hover {
        color: var(--color-base);
      }
    }
  }

  &__title {
    color: var(-color-link);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    span {
      padding-left: 10px;
      color: var(--color-focused1);
    }
  }

  &_filds {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__active {
    background: #FFDD34;
    border-radius: 6px;
  }

  &__field {
    cursor: pointer;
    padding: 3px 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #282D3C;
  }
}

.close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--color-base);
  transition: all 0.4s;

  &:hover {
    color: var(--color-focused);
  }
}

.group {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal {
  width: 100%;
  max-width: 691px;
}