.service {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  // width: 289px;
  height: 319px;

  &_content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 32px 14px;
    background: var(--service-bg);
    z-index: 1;
  }

  &:hover &_content {
    background: var(--service-bg_hover);
  }

  &__name {
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--service-title);
    width: calc(100% - 35px);
  }

  &__go {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background: var(--service-go);

    i {
      color: var(--service-i);
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}