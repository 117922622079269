:root {
  --pt: 50px;
}

.banner {
  display: grid;
  grid-template-columns: [left] 0 [main-content-start] 1fr [main-content-end right-space] 0 [right];
  grid-column-gap: 20px;
  width: 100%;
  color: #fff;
  overflow: hidden;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }


  @media (min-width: 1000px) {
    grid-template-columns: [left] 0 [main-content-start] repeat(12, 1fr) [main-content-end right-space] 0 [right];
  }


  &__picture {
    pointer-events: none;
    grid-column: 1/4;
    grid-row: -1;
    margin: 0 auto;

    @media (min-width: 1000px) {
      grid-column: 1/-1;
      grid-row: -1;
      overflow: hidden;
    }
  }

  &__img {
    pointer-events: none;
    max-width: none;
    grid-column: 1/4;
    grid-row: -1;

    @media (min-width: 1000px) {
      grid-column: 1/-1;
      grid-row: -1;
      max-width: none;
    }

  }
}

.container {
  display: grid;
  grid-column: main-content-start/main-content-end;
  grid-template-columns: [left] 0rem [main-content-start] 1fr [main-content-end right-space] 0rem [right];
  grid-row: -1;

  width: 100%;
  max-width: var(--container);
  margin: 0 auto;

  @media (min-width: 1000px) {
    grid-template-columns: [left] 0rem [main-content-start] repeat(12, 1fr) [main-content-end right-space] 0rem [right];
  }

  @media (min-width: 1300px) {
    grid-column-gap: 20px;
  }
}

.content {
  grid-column: 2;
  padding-top: var(--pt);

  @media (min-width: 1000px) {
    grid-column: 2/9;
  }
}

.form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: var(--pt);
  grid-column: 2;

  @media (min-width: 1000px) {
    grid-column: 9/14;
    justify-content: flex-end;
  }
}

.title {
  margin-top: 30px;
  margin-bottom: 20px;

  br {
    @media (max-width: 480px) {
      display: none;
    }
  }

  @media (min-width: 1000px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  @media (min-width: 1070px) {
    margin-top: 38px;
    margin-bottom: 28px;
  }

}

.short {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: inherit;

  @media (min-width: 1001px) {
    font-size: 15px;
    line-height: 25px;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
  }


  span {
    padding: 3px 10px;
    border-radius: 6px;
    background: var(--bg-short);
    color: #fff;
    white-space: nowrap;

    @media (min-width: 992px) {
      margin-left: 8px;
      display: inline-block;
    }

  }
}

.event {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 34px;

  @media (min-width: 1070px) {
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 40px;
  }


  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 14px;

    @media (min-width: 1070px) {
      font-size: 15px;
      margin-bottom: 0;
      margin-right: 10px;
    }

    i {
      margin-right: 8px;
      font-size: 20px;
      color: var(--color-focused1);

      @media (min-width: 1070px) {
        margin-right: 10px;
        font-size: 24px;
      }
    }

    &:hover {
      i {
        color: var(--bg-short);
      }
    }
  }
}