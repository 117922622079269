.header {
    display: flex;
    flex-direction: column;
}

.top {
    @media (min-width: 320px) {
        order: 2;
    }

    @media (min-width: 768px) {
        order: 1;
    }

    &_columns {
        align-items: center;
        border: 0;

        @media (min-width: 768px) {
            border-bottom: 1px solid #D9D9D9;
        }
    }

    &_empty {
        flex-grow: 1;
    }
}

.logo {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        margin-right: 16px;
    }

    @media (min-width: 1000px) {
        margin-right: 38px;
    }

    @media (min-width: 1240px) {
        margin-right: 50px;
    }

    &__img {
        margin-right: 8px;
        width: 27px;

        @media (min-width: 768px) {
            width: auto;
        }
    }

    &__txt {
        width: 100px;

        @media (min-width: 480px) {
            width: 152px;
            display: block;
        }

        @media (min-width: 768px) {
            width: auto;
            display: none;
        }

        @media (min-width: 1000px) {
            display: block;
        }
    }
}

.middle {
    padding: 20px 0;

    @media (min-width: 320px) {
        order: 1;
    }

    @media (min-width: 768px) {
        order: 2;
    }

    &_content {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-column: main-content-start/main-content-end;
    }
}

.menu {
    order: 3;
    background: #131F39;

    @media (min-width: 320px) {
        display: none;
    }

    @media (min-width: 768px) {
        display: block;
    }
}

.list {
    display: none;
    flex-direction: row;
    padding: 16px 0;
    margin: 0;
    list-style: none;

    @media (min-width: 768px) {
        display: flex;
    }

    li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 18px;

        @media (min-width: 1070px) {
            margin-right: 24px;
        }
    }

    a {

        @media (min-width: 768px) {
            font-size: 14px;
        }

        @media (min-width: 1240px) {
            font-size: 15px;
        }
    }
}


.space {
    flex-grow: 1;
}

.social {
    grid-gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 320px) {
        display: none;
    }

    @media (min-width: 1000px) {
        display: grid;
        margin-left: 40px;
    }

    @media (min-width: 1240px) {
        margin-left: 70px;
    }
}

.mobile {
    &_phone {
        color: var(--color-text);
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        text-decoration: none;

        @media (min-width: 480px) {
            font-size: 16px;
        }

        @media (min-width: 768px) {
            display: none;
        }

        &:hover,
        &:active {
            color: var(--color-success);
        }
    }

    &_menu {
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
        height: calc(100% - 65px);
        background: #131F39;
        display: none;
        z-index: 4;

        &__open {
            display: block;
        }
    }
}