:root {
    --color_link: #fff;
}

.footer {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 80px;
    background: var(--color-base);
    color: var(--color_link);

    @media (min-width: 768px) {
        padding-bottom: 40px;
    }
}

.top {
    margin-bottom: 30px !important;
}

.middle {
    margin-bottom: 40px !important;
}

.info {
    display: grid;
    grid-template-columns: [left] 0rem 1fr 0rem [right];
    grid-column-gap: 20px;
    align-items: center;
    width: 100%;
    max-width: var(--container);
    margin: 30px auto 0 auto;

    @media (min-width: 768px) {
        grid-template-columns: [left] 0rem repeat(11, 1fr) 0rem [right];
    }

    div:first-child {
        display: none;

        @media (min-width: 768px) {
            display: block;
            grid-column: 5;
        }
    }
}

.flexGrow {
    flex-grow: 1;
}

.contact {
    display: none;
    flex-direction: column;
    color: inherit;

    @media (min-width: 768px) {
        display: flex;
    }

    &_phone {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color_link);

        &:hover {
            color: var(--color-focused);
        }
    }

    &_email {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--color_link);

        &:hover {
            color: var(--color-focused);
        }
    }
}

.menu {
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        font-weight: 400;
        font-size: 15px;
        color: var(--color_link);
        line-height: 30px;

        @media (min-width: 768px) {
            line-height: 18px;
        }


        &:hover {
            color: var(--color-focused);
        }
    }
}

.feedback {
    &_form {
        display: flex;
        flex-direction: column;
    }

    &_text {
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
    }

    &__btn {
        background: #FFFFFF;
        min-width: 290px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 10px;

        @media (min-width: 768px) {
            padding: 4px 5px;
        }


        i {
            font-size: 16px;
            color: var(--color-base);
        }

        span {
            margin-left: 11px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: var(--color-signatures);
        }
    }
}

.siteRights {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    grid-column: 2;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 19px;
        grid-column: 5/11;
        text-align: center;
    }
}

.social {
    display: grid;
    grid-template-columns: repeat(3, 23px);
    grid-gap: 8px;
    grid-column: 2;
    grid-row: -1;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(23px, 1fr));
        grid-column: -3;
        grid-row: 2;
        margin-bottom: 0;
    }
}

.mContact {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        display: none;
    }

    &__phone {
        width: 50%;
        height: 60px;
        background: var(--bg-phone);
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
            padding-left: 10px;
            font-size: 15px;
            font-weight: bold;

            @media (min-width: 480px) {
                padding-left: 24px;
            }
        }

        svg {
            width: 24px;
            height: 24px;
            color: var(--bg-whatsap);
        }
    }

    &__whatsap {
        width: 50%;
        height: 60px;
        background: var(--bg-whatsap);
        color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;

        span {
            padding-left: 10px;
            font-size: 12px;
            font-weight: bold;

            @media (min-width: 480px) {
                font-size: 15px;
                padding-left: 24px;
            }

        }

        svg {
            width: 44px;
            height: 44px;
            color: #1AC131;
        }
    }
}