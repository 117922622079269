.container {
    margin: var(--section-mt) auto var(--section-mb);
    max-width: var(--container);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
    }


    @media (min-width: 992px) {
        gap: 80px;
    }
}

.dedicated {
    text-transform: uppercase;

    &+.title {
        margin-top: 27px;
    }
}


.title {
    color: var(--color-text);
}

.desc {
    margin-top: 24px;
    color: var(--color-text);
    font-size: 15px;
    font-weight: 500;
}

.markers {
    margin-top: 30px;
}

.marker {
    max-width: 530px;

    li {
        flex-grow: 1;
    }
}

.image {
    img {
        @media (min-width: 1190px) {
            max-width: none;
        }
    }
}

.txt {
    @media (min-width: 768px) {
        max-width: 60%;
    }

    @media (min-width: 992px) {
        max-width: none;
    }
}

.contact {
    margin-top: 50px;
    display: flex;
    gap: 13px;
    flex-direction: column;

    &__title {
        color: var(--color-text);
        font-family: var(--font-base);
        font-size: 24px;
        font-weight: 700;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;

        @media (max-width: 991px) {
            -ms-overflow-style: none;
            scrollbar-width: none;
            box-sizing: content-box;
            overflow-y: hidden;
            overflow-y: auto;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.list {
    display: flex;
    width: 186px;
    padding: 10px;
    align-items: center;
    gap: 14px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    background: #FFF;

    &__icon {
        width: 30px;
        height: 30px;
    }

    &__title {
        flex: 1 0 0;
        color: var(--color-text);
        font-family: var(--font-base);
        font-size: 13px;
        font-weight: 400;
    }
}