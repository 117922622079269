.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}

.desc {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
}

.bg {
  background: #131F39;
  color: #fff;
}


.info {
  position: relative;
  overflow: hidden;
  padding: 30px 0 30px 30px;
  display: flex;
  grid-column: main-content-start/main-content-end;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(140, 141, 141, 0.1), 0px -4px 10px rgba(140, 141, 141, 0.1), 4px 0px 10px rgba(140, 141, 141, 0.1), -4px 0px 10px rgba(140, 141, 141, 0.1);
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 30px 0 30px 40px;
  }

  @media (min-width: 1000px) {
    padding: 36px 0 36px 30px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 34px;
    bottom: 34px;
    left: 0;
    width: 2px;
    background: #BF0000;
    border-radius: 3px;

    @media (min-width: 768px) {
      top: 40px;
      bottom: 40px;
      width: 4px;
    }

    @media (min-width: 1000px) {
      top: 45px;
      bottom: 45px;
      width: 7px;
    }
  }

  &Wrap {
    background: linear-gradient(180deg, #131F39 50%, #fff 50%);
  }

  &Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  &Title {
    margin: 0 auto 24px;
    width: 100%;
    max-width: 285px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #282D3C;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 100%;
      font-size: 24px;
      line-height: 29px;
    }

    @media (min-width: 1000px) {
      text-align: left;
      margin: 0;
      max-width: 283px;
    }
  }

  &List {
    margin: 0 auto -10px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: 1150px) {
      padding-right: 10%;
    }
  }

  &Item {
    margin: 0 0 10px;
    padding: 0 14px;
    border-right: 2px solid #F5F5F5;

    &:last-child {
      border-right: none;
    }

    &Value {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #282D3C;

      @media (min-width: 768px) {
        font-size: 36px;
        line-height: 44px;
      }
    }

    &Title {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #5A6C7D;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }

  &Success {
    margin: -30px 0;
    padding: 30px 1.5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    background: #131F39;
    writing-mode: tb-rl;
    transform: scale(-1);
    text-align: center;

    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 29px;
      margin: -30px 0;
      padding: 30px 5.5px;
    }

    @media (min-width: 1000px) {
      margin: -36px 0;
      padding: 36px 15px;
    }
  }
}