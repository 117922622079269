.marker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    li {
        position: relative;
        padding-left: 28px;

        @media (min-width: 480px) {
            padding-left: 36px;
        }


        &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 20px;
            height: 20px;
            background: transparent url('../../../../assets/images/icons/red-plus.svg') no-repeat;
            content: '';
            display: block;
        }
    }
}