.pmb15 {
  margin-bottom: 15px;
  color: var(--color-text);
}

.pmb30 {
  margin-bottom: 15px;
  color: var(--color-text);
}

.heading {
  margin-bottom: 30px;
  color: var(--color-text);
  margin-bottom: 25px;

  &--top {
    margin-top: 30px;
  }
}

.desc {
  margin-bottom: 50px;
  color: var(--color-text);
  line-height: 1.5;

  br {
    @media (max-width: 480px) {
      display: none;
    }
  }
}

.container_mb {
  margin-bottom: 50px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }

}