.button {
    font-size: 14px;
    line-height: 17px;
    padding: 20px 30px;
    border: 0;
    transition: all 0.4s ease;
    cursor: pointer;

    @media (min-width: 480px) {
        padding: 20px 60px;
    }

}

.darken {
    color: #fff;
    background: #131F39;
    border: 1px solid #131F39;

    &:hover,
    &:active {
        background: #BF0000;
        border: 1px solid #BF0000;
    }
}

.red {
    color: #fff;
    background: #BF0000;
    border: 1px solid #BF0000;

    &:hover,
    &:active {
        background: transparent;
        border: 1px solid #fff;
    }
}

.redDarken {
    color: #fff;
    background: #BF0000;
    border: 1px solid #BF0000;

    &:hover,
    &:active {
        background: #131F39;
        border: 1px solid #131F39;
    }
}

.ttu {
    text-transform: uppercase;
}