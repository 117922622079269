:root {
  --color-base: #131F39;
  --color-text: #282D3C;
  --color-signatures: #5A6C7D;
  --color-gray: #D9D9D9;
  --color-fields: #F5F5F5;
  --color-focused: #0A3186;
  --color-focused1: #1935C8;
  --color-link: #282D3C;
  --color-primary: #282D3C;
  --color-success: #7BDB79;
  --color-action: #FFDD34;
  --bg-gray: #f5f5f5;
  --bg-phone: #282D3C;
  --bg-whatsap: #7BDB79;
  --bg-short: #BF0000;
  --bg-short_hover: #9B0000;

  --font-base: 'Inter', sans-serif;
  --font-size: 15px;

  --container: 1256px;

  --btn-red: #BF0000;
  --btn-red_hover: #9B0000;
  --btn-red_active: #7BDB79;

  --btn-darken: #131F39;
  --btn-darken_hover: #9B0000;
  --btn-darken_active: #BF0000;

  --input-border: #D9D9D9;
  --input-color: #131F39;
  --input-border_err: #BF0000;

  --service-bg: #131F39CC;
  --service-bg_hover: #BF0000CC;
  --service-go: #F5F5F5;
  --service-i: #131F39;
  --service-title: #fff;

  --section-mt: 105px;
  --section-mb: 105px;
}

.container {
  display: grid;
  grid-template-columns: [left] 0rem [main-content-start] repeat(12, 1fr) [main-content-end right-space] 0rem [right];
  grid-column-gap: 20px;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  grid-column: main-content-start/main-content-end;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

.columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: main-content-start/main-content-end;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}

.wap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: main-content-start/main-content-end;
  width: 100%;
  max-width: var(--container);
  margin-left: auto;
  margin-right: auto;
}