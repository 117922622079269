.pmb15 {
  margin-bottom: 15px;
  color: var(--color-text);
  line-height: 1.5;
}

.pmb30 {
  margin-bottom: 15px;
  color: var(--color-text);
  line-height: 1.5;
}

.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}