.pmb15 {
  margin-bottom: 15px;
  color: var(--color-text);
  line-height: 1.5;
}

.pmb30 {
  margin-bottom: 15px;
  color: var(--color-text);
  line-height: 1.5;
}

.heading {
  margin-bottom: 30px;
  color: var(--color-text);

  &--top {
    margin-top: 30px;
  }
}

.body {
  line-height: 1.6;

  p {
    margin-bottom: 10px;
  }

  h3 {
    margin-top: 30px;
    margin-bottom: 25px;
  }

  ul {
    padding-left: 40px;
    margin-bottom: 15px;
  }
}