.askExpert {
  max-width: 509px;
  width: 100%;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(140, 141, 141, 0.1), 0px -4px 10px rgba(140, 141, 141, 0.1), 4px 0px 10px rgba(140, 141, 141, 0.1), -4px 0px 10px rgba(140, 141, 141, 0.1);
  border-radius: 8px;
  padding: 14px;

  @media (min-width: 1070px) {
    max-width: 598px;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  &__ava {
    margin-right: 17px;
  }

  &__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-text);

    div {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.desc {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-signatures);
  margin-bottom: 6px;

  @media (max-width: 767px) {
    display: none;
  }
}

.link {
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-focused1);
}