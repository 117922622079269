.container {
    position: relative;
    padding: 8.5px 0;
    overflow: hidden;
}

.menu {
    display: flex;
    grid-column: main-content-start/main-content-end;
    width: 100%;
    white-space: nowrap;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 0.2s ease;

    &__link {
        display: inline-block;
        white-space: nowrap;
        margin-right: 24px;

        a {
            color: #FFFFFF;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.2;
            text-transform: uppercase;
        }
    }
}

.next {
    position: absolute;
    top: 4px;
    right: 0px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: var(--color-fields);
    color: var(--color-base);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.prev {
    position: absolute;
    top: 4px;
    left: 0px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: var(--color-fields);
    color: var(--color-base);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.hide {
    display: none !important;
}