.item {
  display: flex;
  flex-direction: column;
}

.image {
  position: relative;
  width: 100%;
  height: 280px;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: top left;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.link {
  margin-top: 24px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-link);
}