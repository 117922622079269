.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;

  i {
    color: var(--color-success);
    font-size: 64px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--color-text);

    @media (min-width: 480px) {
      font-size: 15px;
    }

  }
}