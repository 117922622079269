.marker {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;

    li {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 18px;
        padding-left: 86px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 70px;
            height: 270px;
            background: transparent url('../../../../assets/images/icons/white-plus.svg') no-repeat;
            content: '';
            display: block;
        }
    }
}

.title {
    color: var(--color-text);
    font-family: var(--font-base);
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;

    @media (min-width: 992px) {
        font-size: 24px;
    }
}

.desc {
    color: var(--color-text);
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 13px;

    @media (min-width: 992px) {
        font-size: 15px;
    }
}