.news {
  margin-top: 30px;
}

.swiper {
  width: 100%;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 36px;
  margin-bottom: 36px;

  @media (min-width: 480px) {
    margin-top: 10px;
  }

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-base);
    color: #fff;
    width: 50px;
    height: 50px;
    cursor: pointer;

    &.swiper-button-disabled {
      opacity: 0.4;
    }

    i {
      font-size: 30px;
    }
  }

  &__next {
    margin-left: 17px;
  }
}