:root {
    --menu-speed: 0.75s;
    --menu-size: 24px;
    --menu-padding: 0.5rem;
}

.warp {
    position: relative;
    width: var(--menu-size);
    height: var(--menu-size);
    padding: var(--menu-padding);
    padding-left: 0;
    display: inline-block;
    margin-right: 8px;

    @media (min-width: 480px) {
        margin-right: 24px;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: var(--menu-size);
    height: var(--menu-size);
    padding: var(--menu-padding);
    padding-left: 0;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger>div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: var(--color-link);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
}

.hamburger>div::before,
.hamburger>div::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -8px;
    width: 100%;
    height: 2px;
    background: inherit;
}

.hamburger>div::after {
    top: 8px;
}

.open {
    &.hamburger>div {
        transform: rotate(135deg);
    }

    &.hamburger>div:before,
    &.hamburger>div:after {
        top: 0;
        transform: rotate(90deg);
    }

    &:hover+.hamburger>div {
        transform: rotate(225deg);
    }
}