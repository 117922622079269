.productsWeCertify {
    background-position: top left;
    background: transparent url('../../assets//images/productsWeCertify/bg.png') no-repeat;
    background-size: cover;
    display: block;
    padding-bottom: 50px;
}

.heading {
    margin-top: 50px;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column: main-content-start/main-content-end;
    width: 100%;
    margin-top: 30px;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 24px;
}

.item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: calc(50% - 20px);

    @media (min-width: 992px) {
        width: calc(33% - 16px);
    }

}

.image {
    flex-shrink: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    color: var(--color-text);
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;

    @media (min-width: 992px) {
        font-size: 24px;
    }
}

.desc {
    color: var(--color-text);
    font-family: var(--font-base);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}