.modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 440px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #282D3C;

  @media (min-width: 480px) {
    font-size: 15px;
  }

}

.info {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #282D3C;

  b {
    font-weight: normal;
    color: #1935C8;
  }
}

.form {
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
  }
}

.input {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #282D3C;
  border: 0;
  padding: 22px 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  background: #F5F5F5;
  border-radius: 5px;

  @media (min-width: 480px) {
    width: 232px;
    margin-bottom: 0;
  }
}

.btn {
  padding: 20px 20px;
  background: var(--btn-red);
  border-radius: 5px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
  display: table;
  margin: 0 auto;
  transition: all 0.6s;

  @media (min-width: 480px) {
    margin: 0;
    border-radius: 0px 5px 5px 0px;
    display: inline-block;
  }

  &:active,
  &:hover {
    background: var(--btn-darken);
  }

  &[disabled] {
    background: #c7c7c7;
  }
}