.container {
    margin: var(--section-mt) auto var(--section-mb);
    max-width: var(--container);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
        flex-direction: row;
    }

}

.dedicated {
    text-transform: uppercase;

    &+.title {
        margin-top: 27px;
    }
}


.title {
    color: var(--color-text);
}

.desc {
    margin-top: 24px;
    color: var(--color-text);
    font-size: 15px;
    font-weight: 500;
}

.markers {
    max-width: 440px;
    margin-top: 30px;
}

.marker {
    li {
        width: calc(50% - 36px);
        flex-grow: 1;
    }
}

.image {
    img {
        @media (min-width: 1190px) {
            max-width: none;
        }

    }
}

.contact {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;

    @media (min-width: 480px) {
        flex-direction: row;
    }

    @media (min-width: 768px) {
        flex-direction: column;
        margin-bottom: 0;
    }

    @media (min-width: 992px) {
        gap: 50px;
        flex-direction: row;
    }
}

.info {
    display: flex;
    gap: 15px;
    flex-direction: row;

    &__ava {
        @media (min-width: 992px) {
            flex-grow: 1;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__title {
        color: var(--color-signatures);
        font-family: var(--font-base);
        font-size: 13px;
        font-weight: 400;
    }

    &__name {
        color: var(--color-text);
        font-family: var(--font-base);
        font-weight: 700;
        font-size: 15px;

        @media (min-width: 768px) {
            font-size: 20px;
        }
    }
}