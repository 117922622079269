.bookmark {
  padding: 3px 10px;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #BF0000;
  border-radius: 6px;
}
