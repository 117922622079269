@import '../../../styles/mixins';

.item {
    display: flex;
    width: 100%;
    padding: 12px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -4px 0px 10px 0px rgba(140, 141, 141, 0.10), 4px 0px 10px 0px rgba(140, 141, 141, 0.10), 0px -4px 10px 0px rgba(140, 141, 141, 0.10), 0px 4px 10px 0px rgba(140, 141, 141, 0.10);
}

.image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.contant {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-family: var(--font-base);
    font-size: 14px;
    color: #000;
}

.desc {
    font-size: 13px;
    font-family: var(--font-base);
    color: #000;
}