.header {
    width: 100%;
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-gray);
    background: var(--btn-darken);
    color: #fff;
    font-family: var(--font-base);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-top: 24px;
}

.items {
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    gap: 24px;
    border-bottom: 1px solid var(--color-gray);

    &:nth-child(odd) {
        background: #F5F5F5;
    }

    &_content {
        display: flex;
        flex-direction: column;
        gap: 11px;
        flex-grow: 1;
    }

    &_title {
        color: var(--color-text);
        font-family: var(--font-base);
        font-size: 14px;
        font-weight: 700;

        @media (min-width: 992px) {
            font-size: 20px;
        }

    }

    &_desc {
        color: var(--color-text);
        font-family: var(--font-base);
        font-weight: 400;
        font-size: 15px;
    }

    &_price {
        flex-shrink: 0;
        color: var(--color-text);
        font-family: var(--font-base);
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;

        @media (min-width: 992px) {
            font-size: 20px;
            font-weight: 700;
        }
    }
}

.next{
    margin-top: 24px;
    color: var(--color-focused1);
    font-family: var(--font-base);
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
}